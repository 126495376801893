import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.75 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_157_429)">
      <path d="M19.9194 10.5287C21.3692 10.5287 22.5444 9.35347 22.5444 7.90372C22.5444 6.45398 21.3692 5.27872 19.9194 5.27872C18.4697 5.27872 17.2944 6.45398 17.2944 7.90372C17.2944 9.35347 18.4697 10.5287 19.9194 10.5287Z" stroke="#747894" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.29443 19.5287H2.29443C2.09552 19.5287 1.90476 19.4497 1.7641 19.3091C1.62345 19.1684 1.54443 18.9776 1.54443 18.7787V15.0287C1.54443 14.8298 1.62345 14.639 1.7641 14.4984C1.90476 14.3577 2.09552 14.2787 2.29443 14.2787H5.29443" stroke="#747894" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.2944 15.0287H14.2944L20.5757 13.584C20.8066 13.5208 21.049 13.5116 21.2841 13.5572C21.5191 13.6029 21.7405 13.7021 21.931 13.8472C22.1214 13.9923 22.2759 14.1794 22.3823 14.3939C22.4887 14.6084 22.5442 14.8446 22.5444 15.084C22.5446 15.3731 22.4641 15.6566 22.3121 15.9025C22.16 16.1484 21.9425 16.3471 21.6838 16.4762L18.0444 18.0287L12.0444 19.5287H5.29443V14.2787L7.63818 11.935C7.84767 11.7262 8.09626 11.5608 8.36973 11.4482C8.6432 11.3356 8.93619 11.278 9.23193 11.2787H13.9194C14.4167 11.2787 14.8936 11.4763 15.2453 11.8279C15.5969 12.1795 15.7944 12.6564 15.7944 13.1537C15.7944 13.651 15.5969 14.1279 15.2453 14.4795C14.8936 14.8312 14.4167 15.0287 13.9194 15.0287H11.2944Z" stroke="#747894" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.2944 8.02466C16.785 8.26562 16.2115 8.33621 15.6588 8.22599C15.1061 8.11578 14.6035 7.83061 14.2254 7.41266C13.8474 6.99472 13.6138 6.46619 13.5594 5.90525C13.505 5.34431 13.6325 4.78074 13.9231 4.29789C14.2138 3.81505 14.6521 3.43856 15.1733 3.22412C15.6945 3.00968 16.2709 2.96868 16.8172 3.10717C17.3635 3.24565 17.8507 3.55629 18.2068 3.99312C18.5629 4.42995 18.7689 4.96979 18.7944 5.53279" stroke="#747894" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_27_1128">
        <rect width="24" height="24" fill="white" transform="translate(0 0.75)"/>
      </clipPath>
    </defs>
  </svg>
}
