import React, { FormEvent, ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { IconButton, PrimaryButton } from '../../components/Button'
import { RADOM_COLORS } from '../../util/Constants'
import { InputLabel, TextInputWithLabel } from '../../components/Input'
import { RadioButtonGroup } from '../../components/RadioButton'
import Radom, { IProduct } from '../../state/Radom'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import { errorToast, successToast, SecondsToPaymentPeriod } from '../../util/Util'
import Web3 from '../../state/User'
import Spinner from '../../components/Spinner'
import Close from '../../icons/Close'
import NumberInput from '../../components/NumberInput'
import { Currencies } from '../../util/Currencies'
import DateSelector from '../../components/DateSelector'
import Checkbox from '../../components/Checkbox'
import { endOfDay, startOfDay } from 'date-fns'

const PageSectionTitle = styled.h2`
  margin: 32px 0 16px 0;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  font-weight: 400;
`

export const CreateDiscount = (): ReactElement => {
  const [codeName, setCodeName] = useState('')
  const [isFixed, setIsFixed] = useState(false)
  const [amountOff, setAmountOff] = useState('')

  const [products, setProducts] = useState([] as IProduct[])
  const [selectedProducts, setSelectedProducts] = useState([] as IProduct[])
  const [closeProductFn, setCloseProductFn] = useState(() => () => { })

  const [startDate, setStartDate] = useState<Date>()
  const [expireDate, setExpireDate] = useState<Date>()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const loadProducts = async (): Promise<void> => {
    Radom.getProducts(false)
      .then(products => {
        setProducts(products.data)
        return products
      })
      .then((products) => {
        if (searchParams.has('product_id')) {
          const selectedProduct = products.data.find(p => p.id === searchParams.get('product_id'))
          if (selectedProduct) {
            setSelectedProducts([selectedProduct])
          }
        }
      })
  }

  useEffect(() => { loadProducts() }, [])

  const handleCreateDiscountCode = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    try {
      await Radom.createDiscountCode({
        code: codeName,
        products: selectedProducts.map(p => p.id),
        model: {
          modelType: isFixed ? 'fixed' : 'percentage',
          amountOff: Number(amountOff)
        },
        startsAt: startDate ? startOfDay(startDate) : undefined,
        expiresAt: expireDate ? endOfDay(expireDate) : undefined
      })

      successToast('Created discount code!')
      navigate('/products?tab=Discount codes')
    } catch (err) {
      console.error(err)
      errorToast(err.response ? err.response.data.error : err.message)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 30 }}>
      <div style={{ fontSize: 14, maxWidth: '400px', width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
          <h1 style={{ fontWeight: 300 }}>Create a discount code</h1>
        </div>

        <form onSubmit={handleCreateDiscountCode}>
          <PageSectionTitle>Discount code details</PageSectionTitle>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <TextInputWithLabel
              label='Code name'
              value={codeName}
              placeholder='10OFF'
              onChange={(e) => setCodeName((e.target as HTMLInputElement).value)}
              required={true}
            />

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>

              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <InputLabel>Products</InputLabel>
              </div>
              <Dropdown
                preferredAlignment='right'
                onCloseFn={_closeFn => setCloseProductFn(() => _closeFn)}
                selectedContent={
                  <div style={{
                    display: 'flex',

                    alignItems: 'center',
                    gap: 8,
                    flexWrap: 'wrap'
                  }}>
                    {Web3.isLoading && <Spinner />}
                    {(!Web3.isLoading && !selectedProducts.length) && <div>Select products</div>}
                    {
                      !Web3.isLoading && selectedProducts.length > 0 &&
                      selectedProducts.map(p =>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: 5,
                          gap: 5,
                          backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
                          padding: 5
                        }} key={p.id}>
                          {p.imageUrl && <img src={p.imageUrl} style={{ width: 20 }} />}
                          <span>{p.name}</span>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                            {Currencies.find(c => c.ticker.toLowerCase() === p.currency.toLowerCase())?.icon}
                            <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(p.price)}</span>
                          </div>
                          {
                            p.chargingIntervalSeconds !== undefined && p.chargingIntervalSeconds > 0 &&
                            <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                              per {SecondsToPaymentPeriod(p.chargingIntervalSeconds).toLowerCase()}
                            </span>
                          }
                          <IconButton style={{ padding: 5 }} onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()

                            setSelectedProducts(selectedProducts.filter(sp => sp.id !== p.id))
                          }}>
                            <Close style={{ width: 15, pointerEvents: 'none' }} />
                          </IconButton>
                        </div>
                      )
                    }
                  </div>
                }
                dropdownContent={
                  <div>
                    {products
                      .filter(p => !selectedProducts.includes(p))
                      .map(p =>
                        <DropdownItem
                          key={p.id}
                          style={{ display: 'flex', alignItems: 'center', gap: 10 }}
                          onClick={() => {
                            setSelectedProducts([...selectedProducts, p])
                            closeProductFn()
                          }}>
                          {p.imageUrl && <img src={p.imageUrl} style={{ width: 20 }} />}
                          <span>{p.name}</span>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                            {Currencies.find(c => c.ticker.toLowerCase() === p.currency.toLowerCase())?.icon}
                            <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(p.price)}</span>
                          </div>
                          {
                            p.chargingIntervalSeconds !== undefined && p.chargingIntervalSeconds > 0 &&
                            <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                              per {SecondsToPaymentPeriod(p.chargingIntervalSeconds).toLowerCase()}
                            </span>
                          }
                        </DropdownItem>
                      )}
                  </div>
                }
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <InputLabel>Discount model</InputLabel>
              <RadioButtonGroup
                labels={['Fixed amount', 'Percentage based']}
                selectedLabel={isFixed ? 'Fixed amount' : 'Percentage based'}
                onSelect={label => setIsFixed(label === 'Fixed amount')}
              />

              <NumberInput
                value={amountOff}
                onUserInput={v => setAmountOff(v)}
                fontSize='14'
                required placeholder='Enter amount off' />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <div onClick={() => setStartDate(startDate ? undefined : new Date())}
                style={{ display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer' }}>
                <Checkbox checked={!!startDate} onClick={() => setStartDate(startDate ? undefined : new Date())} />
                <InputLabel>Add start date</InputLabel>
              </div>

              {
                startDate &&
                <DateSelector
                  min={new Date()}
                  value={startDate}
                  onChange={date => {
                    // if (date) {
                    // const utcVal = convertDateToUTC(date)
                    // }
                    setStartDate(date)
                  }}
                />
              }
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <div onClick={() => setExpireDate(expireDate ? undefined : new Date())}
                style={{ display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer' }}>
                <Checkbox checked={!!expireDate} onClick={() => setExpireDate(expireDate ? undefined : new Date())} />
                <InputLabel>Add expiration date</InputLabel>
              </div>

              {
                expireDate &&
                <DateSelector
                  min={new Date()}
                  value={expireDate}
                  onChange={date => {
                    // if (date) {
                    // const utcVal = convertDateToUTC(date)
                    // }
                    setExpireDate(date)
                  }}
                />
              }
            </div>
          </div>

          <PrimaryButton
            style={{ display: 'block', marginTop: '32px', width: '100%' }}
            disabled={!codeName || products.length === 0 || !amountOff}>
            Create discount code
          </PrimaryButton>
        </form>
      </div>
    </div>
  )
}
