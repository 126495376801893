import { observer } from 'mobx-react-lite'
import React, { ReactElement, useEffect, useReducer, useRef, useState } from 'react'
import styled from 'styled-components'
import { HoverText, IconButton, PrimaryButton } from './Button'
import { InputLabel, TextArea, TextInput } from './Input'
import Radom, { AcceptedToken, Customer, IProduct, LineItem, MultiCreateInvoice, ProductWithQuantity } from '../state/Radom'
import { RADOM_COLORS, WINDOW_SIZE } from '../util/Constants'
import CreateProduct from '../views/Products/CreateProduct'
import Modal from './Modal'
import Close from '../icons/Close'
import CustomerForm from './CustomerForm'
import { Tokens, getToken } from '../util/Tokens'
import SearchMultiselect from './SearchMultiselect'
import NumberInput from './NumberInput'
import { Currencies } from '../util/Currencies'
import Checkbox from './Checkbox'
import { convertDateToUTC, errorToast, formatCurrency } from '../util/Util'
import DateSelector from './DateSelector'
import { defaultCustomer, FormStep } from '../views/Invoices/CreateInvoice'
import NavbarController from '../state/NavbarController'
import { useNavigate } from 'react-router-dom'
import Dropdown, { DropdownItem } from './Dropdown'
import { Chevron } from '../icons/Chevron'
import { TokenDisplay } from './TokenDisplay'
import { Send } from '../icons/Send'
import { cloneDeep } from 'lodash'
import { ManagedPaymentMethodSelector } from './ManagedMethodMultiselector'
import { ManagedPaymentMethod, getMethod } from '../util/Managed'
import PaymentMethodDisplay from './PaymentMethodDisplay'

export enum PaymentType {
  FIAT,
  CRYPTO
}

export interface AcceptedPaymentCurrency {
  ticker: string
  type: PaymentType
  logo?: string
}

export const ACCEPTED_PAYMENT_CURRENCIES: AcceptedPaymentCurrency[] = [
  {
    ticker: 'USD',
    type: PaymentType.FIAT
  },
  {
    ticker: 'CAD',
    type: PaymentType.FIAT
  },
  {
    ticker: 'GBP',
    type: PaymentType.FIAT
  },
  {
    ticker: 'EUR',
    type: PaymentType.FIAT
  },
  {
    ticker: 'USDC',
    type: PaymentType.CRYPTO,
    logo: Tokens.USDC.logo
  },
  {
    ticker: 'USDT',
    type: PaymentType.CRYPTO,
    logo: Tokens.USDT.logo
  }
]

interface IItemProps {
  index: number
  item: LineItem & { id: string } | IProduct & { quantity: number }
  products: ProductWithQuantity[]
  onFormChange: (form: Partial<MultiCreateInvoice>) => void
  onRemoveItem: (e: React.MouseEvent, item: { id: string }) => void
  onUserInput: (input: string) => void
  visible: boolean
}

const InvoiceItem = (props: IItemProps): React.ReactElement => {
  return <LayoutFade visible={props.visible}>
    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
        <span>{props.item.name}</span>
        <div style={{ marginTop: 8 }}>
          <span style={{ color: RADOM_COLORS.GRAY_DARKER, marginRight: 8 }}>Qty</span><NumberInput
            fontSize="12px"
            step={1}
            value={props.item.quantity}
            style={{ width: '40px', height: '20px' }}
            placeholder="Qty"
            onPaste={e => {
              e.clipboardData.getData('Text').includes('.') &&
              e.preventDefault()
            }}
            onKeyDown={e => {
              e.key === '.' && e.preventDefault()
            }}
            onUserInput={props.onUserInput} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'start',
          gap: 4,
          marginRight: '14px'
        }}
      >
        {
          props.item.currency &&
          Object.keys(Tokens).includes(props.item.currency) &&
          <div style={{ marginTop: 1 }}>
            <img src={Tokens[props.item.currency].logo} style={{ width: 15, height: 15 }} />
          </div>
        }
        {
          formatCurrency(props.item.price * props.item.quantity, props.item.currency ?? 'USD')
        }
      </div>
      <IconButton style={{ padding: 5 }} onClick={(e) => props.onRemoveItem(e, props.item)}>
        <Close style={{ width: 10, pointerEvents: 'none' }} />
      </IconButton>
    </div>
  </LayoutFade>
}

const defaultLineItem: any = {
  invoiceId: '',
  name: '',
  price: '',
  quantity: '',
  currency: 'USD'
}

const InvoiceFormEl = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`

const InvoiceFormInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  font-size: 14px;
  row-gap: 30px;
  background-color: white;
`

const DateGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  >:nth-child(2n) {
    justify-self: start;
  }

  @media (max-width: ${WINDOW_SIZE.TABLET}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const LayoutFade = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  opacity: ${({ visible }) => `${visible ? '100%' : '33%'}`};
  pointer-events: ${({ visible }) => `${visible ? 'all' : 'none'}`};
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

const AddButton = styled.div`
  width: 100%;
  padding: 14px;
  color: ${RADOM_COLORS.BLACK};
  cursor: pointer;

  :hover {
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
  }
`

const ReviewGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 24px;
`

const ReviewSubheader = styled.div`
  font-size: 16px;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  margin-bottom: 8px;
  border-bottom: 1px solid ${RADOM_COLORS.GRAY_MED};
  padding-bottom: 8px;
`

interface IProps {
  header?: ReactElement
  isLoading?: boolean
  isSubmitting?: boolean
  invoice: MultiCreateInvoice
  edit?: boolean
  formStep: FormStep
  onFormChange: (form: Partial<MultiCreateInvoice>) => void
  onFormSubmit: () => void
  onFormStep: (step: FormStep) => void
}

const InvoiceForm = observer((props: IProps) => {
  const navigate = useNavigate()
  const productsRef = useRef<HTMLDivElement>(null)
  const lineItemsRef = useRef<HTMLDivElement>(null)

  const [hasExpiry, setHasExpiry] = useState(false)
  const [hasMemo, setHasMemo] = useState(false)

  const [customers, setCustomers] = useState<Customer[]>([])
  const [customerFormVisible, setCustomerFormVisible] = useState(false)

  const [products, setProducts] = useState<ProductWithQuantity[]>([])
  const [productFormVisible, setProductFormVisible] = useState(false)

  const [selectedMethods, setSelectedMethods] = useState<ManagedPaymentMethod[]>([])

  const [lineCurrencyCloseFn, setLineCurrencyCloseFn] = useState<any>({})

  const [newLineItem, updateNewLineItem] = useReducer((p: LineItem, n: Partial<LineItem>) => {
    return { ...p, ...n }
  }, defaultLineItem)

  const [lineFormVisible, setLineFormVisible] = useState(false)
  const lineFormRef = useRef<HTMLDivElement>(null)

  const [selectedTokens] = useState<AcceptedToken[]>([])

  const validateInvoiceForm = (): boolean => {
    let msg: string | undefined
    if (!props.edit && !props.invoice.customers.length) {
      msg = 'Must select a customer'
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    if (!props.invoice.products.length && !props.invoice.lineItems.length) {
      msg = 'Must have at least one product or line item'
      productsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
    }

    if (
      props.invoice.products.length > 0 &&
      props.invoice.products.some((p: ProductWithQuantity) => p.quantity === 0)
    ) {
      msg = 'Products must have quantities greater than zero'
      productsRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    if (props.invoice.lineItems.length > 0) {
      if (props.invoice.lineItems.some((l: LineItem) => l.quantity === 0)) {
        msg = 'Line items must have quantities greater than zero'
        lineItemsRef.current?.scrollIntoView({ behavior: 'smooth' })
      }

      if (props.invoice.lineItems.some(l => l.name === '' || l.name === undefined)) {
        msg = 'Line items cannot have a blank name'
        lineItemsRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }

    if (msg) {
      errorToast(msg)
      return false
    }

    return true
  }

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (props.formStep === FormStep.INVOICE) {
      if (!validateInvoiceForm()) return
      props.onFormStep(FormStep.CONFIRM)
    } else if (props.formStep === FormStep.CONFIRM) {
      props.onFormSubmit()
    }
  }

  const onCreateProduct = (product: IProduct): void => {
    setProductFormVisible(false)
    loadProducts()
      .then(() => {
        props.onFormChange?.({ products: [...props.invoice.products, { product, quantity: 1 }] })
      })
  }

  const onRemoveItem = (e: React.MouseEvent, item: { id: string }): void => {
    e.preventDefault()
    e.stopPropagation()
    if (item.id.startsWith('line-')) {
      const newLineItems = props.invoice.lineItems.slice()
      const idx = Number(item.id.split('-')[1])
      newLineItems.splice(idx, 1)
      props.onFormChange?.({ lineItems: newLineItems })
    } else {
      const newProducts = props.invoice.products.slice()
      const idx = props.invoice.products.findIndex(p => p.product.id === item.id)
      newProducts.splice(idx, 1)
      props.onFormChange?.({ products: newProducts })
    }
  }

  const onLineItemSubmit = (addAnother: boolean = false): void => {
    const line = cloneDeep(newLineItem)
    if (line.name.length === 0) {
      errorToast('Cannot add a new line item without a name')
      return
    }
    if (Number(line.quantity) === 0) {
      line.quantity = 1
    }
    props.onFormChange?.({ lineItems: [...props.invoice.lineItems, line] })
    updateNewLineItem(defaultLineItem)
    setLineFormVisible(addAnother)
  }

  const onCreateCustomer = (customer: Customer): void => {
    setCustomerFormVisible(false)
    loadCustomers()
      .then(() => {
        props.onFormChange?.({ customers: [...props.invoice.customers, customer] })
      })
  }

  const onExpirySwitch = (): void => {
    props.onFormChange?.({ overdueAt: !hasExpiry ? new Date() : undefined })
    setHasExpiry(!hasExpiry)
  }

  const onMemoSwitch = (): void => {
    props.onFormChange?.({ memo: '' })
    setHasMemo(!hasMemo)
  }

  const loadProducts = async (): Promise<void> => {
    Radom.getProducts()
      .then(products => {
        setProducts(products.data.map(p => {
          return {
            product: {
              ...p,
              addOns: []
            },
            quantity: 0
          }
        }))
      })
  }

  const loadCustomers = async (): Promise<void> => {
    Radom.listCustomers(0, 100, [])
      .then(c => {
        setCustomers(c.data)
      })
  }

  const productTotal = (): number => {
    return props.invoice.products
      .map((p: ProductWithQuantity) => p.quantity * p.product.price)
      .reduce((p: number, n: number) => p + n, 0)
  }

  const lineItemTotal = (): number => {
    return props.invoice.lineItems
      .map((line: LineItem) => line.quantity * line.price)
      .reduce((p: number, n: number) => p + n, 0)
  }
  const currency = (): string => {
    if (props.invoice.lineItems.length > 0) {
      return props.invoice.lineItems[0].currency ?? 'USD'
    }

    if (props.invoice.products.length > 0) {
      return props.invoice.products[0].product.currency
    }

    return 'USD'
  }

  const overdueDate = (): string => {
    return props.invoice.overdueAt?.toISOString().split('T')[0] ?? (new Date().toISOString().split('T')[0])
  }

  useEffect(() => {
    if (selectedTokens.length > 0) {
      props.onFormChange?.({ gateway: { selfCustodial: { tokens: selectedTokens } } })
    }
  }, [selectedTokens])

  useEffect(() => {
    if (!props.invoice.gateway.managed) {
      return
    }

    props.onFormChange({
      gateway: {
        managed: {
          methods: selectedMethods.map(m => ({
            network: m.hostChain?.name ?? m.name,
            token: m.hostChain?.tokenAddress ?? undefined
          }))
        }
      }
    })
  }, [selectedMethods])

  useEffect(() => {
    if (lineFormVisible) {
      window.setTimeout(() => lineFormRef.current?.scrollIntoView({ behavior: 'smooth' }), 100)
    }
  }, [lineFormVisible])

  useEffect(() => {
    NavbarController.setFullscreen(true)
    return () => NavbarController.setFullscreen(false)
  }, [])

  useEffect(() => {
    loadCustomers()
    loadProducts()
  }, [])

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '600px',
        backgroundColor: 'white'
      }}>
        <div style={{
          position: 'sticky',
          top: 0,
          padding: '20px 30px',
          backgroundColor: 'white',
          borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`,
          zIndex: 3,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          gap: 10
        }}>
          {
            props.formStep === FormStep.INVOICE &&
            <IconButton type="button" style={{ padding: 5 }} onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate('/invoices')
            }}>
              <Close style={{ width: 15, pointerEvents: 'none' }} />
            </IconButton>
          }
          {
            props.formStep !== FormStep.INVOICE &&
            <IconButton type="button" style={{ padding: 5 }} onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (props.formStep === FormStep.INVOICE) return
              props.onFormStep(props.formStep - 1)
            }}>
              <Chevron style={{ transform: 'rotate(90deg)', height: 'auto', minWidth: 15 }} fill="rgba(0, 0, 0, 0.25)" />
            </IconButton>
          }
          {props.header}
        </div>
        <InvoiceFormEl onSubmit={onFormSubmit}>
          <InvoiceFormInner>
            {
              props.formStep === FormStep.INVOICE && <>
                <LayoutFade visible={!lineFormVisible}>
                  <InputLabel style={{ marginBottom: 8 }}>Customer</InputLabel>
                  <SearchMultiselect
                    isSingleSelect={props.edit}
                    isLoading={props.isLoading}
                    placeholder="Select customer"
                    dropdownPlaceholder="No customers found"
                    itemArray={customers}
                    selectedItems={!props.edit
                      ? props.invoice.customers
                      : [props.invoice.customer ?? defaultCustomer]
                    }
                    setSelectedItems={customers => {
                      if (!props.edit) {
                        props.onFormChange({ customers })
                      }
                    }}
                    headers={[
                      <AddButton key="create_customer" onClick={() => setCustomerFormVisible(true)}>
                        <span>+ Create customer</span>
                      </AddButton>
                    ]}
                    keyExtractor={(customer) => `${customer.id}${customer.name}${customer.email}`}
                    labelExtractor={customer => customer.name ? `${customer.name} (${customer.email})` : customer.email}
                    onNewItem={() => setCustomerFormVisible(true)}
                  />
                </LayoutFade>

                {
                  props.invoice.gateway.managed &&
                  <LayoutFade visible={!lineFormVisible}>
                    <ManagedPaymentMethodSelector
                      allowTestnets
                      selectedMethods={props.invoice.gateway.managed.methods.map(m => getMethod(m.network, m.token))}
                      setSelectedMethods={setSelectedMethods} />
                  </LayoutFade>
                }

                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                  {/* Dates */}
                  <DateGrid>
                    <LayoutFade visible={!lineFormVisible}>
                      <div
                        onClick={onExpirySwitch}
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                          userSelect: 'none',
                          cursor: 'pointer'
                        }}>
                        <Checkbox checked={hasExpiry} onClick={onExpirySwitch} />
                        <span>Invoice due date</span>
                      </div>
                    </LayoutFade>
                    {
                      hasExpiry &&
                    <LayoutFade visible={hasExpiry && !lineFormVisible}>
                      <DateSelector
                        min={new Date()}
                        value={new Date(overdueDate())}
                        onChange={date => {
                          if (date) {
                            const utcVal = convertDateToUTC(date)
                            props.onFormChange?.({ overdueAt: utcVal })
                          }
                        }}
                      />
                    </LayoutFade>
                    }
                  </DateGrid>

                  {/* Memo */}
                  <div style={{ width: '100%', display: 'grid', alignItems: 'start', gridTemplateColumns: '1fr', rowGap: 20 }}>
                    <LayoutFade visible={!lineFormVisible}>
                      <div
                        onClick={onMemoSwitch}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 8,
                          cursor: 'pointer',
                          userSelect: 'none'
                        }}>
                        <Checkbox checked={hasMemo} onClick={onMemoSwitch} />
                        <span>Invoice has memo</span>
                      </div>
                    </LayoutFade>
                    {
                      hasMemo && <LayoutFade visible={hasMemo && !lineFormVisible}>
                        <TextArea
                          placeholder="Memo"
                          value={props.invoice.memo}
                          rows={6}
                          onChange={e => {
                            props.onFormChange?.({ memo: (e.target as HTMLTextAreaElement).value })
                          }}
                        />
                      </LayoutFade>
                    }
                  </div>
                </div>
                {/* Products */}
                <div ref={productsRef}>
                  <LayoutFade visible={!lineFormVisible} style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 20 }}>
                    <InputLabel>Products</InputLabel>
                    {/* Select products */}
                    <SearchMultiselect
                      hideSelectedContent
                      placeholder="Select products"
                      dropdownPlaceholder="No products found"
                      itemArray={products.filter(p => p.product.chargingIntervalSeconds === 0).map(p => p.product)}
                      selectedItems={props.invoice.products.map(p => p.product)}
                      setSelectedItems={p => props.onFormChange?.({
                      // Keep quantities for other products
                        products: p.map(product => {
                          const quantity = props.invoice.products
                            .find(pp => pp.product.id === product.id)?.quantity ?? 1
                          return {
                            product,
                            quantity
                          }
                        })
                      })}
                      keyExtractor={p => p.name + p.id}
                      labelExtractor={p =>
                        <>
                          {p.imageUrl && <img src={p.imageUrl} style={{ width: 20 }} /> }
                          <span>{p.name}</span>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                            {Currencies.find(c => c.ticker.toLowerCase() === p.currency.toLowerCase())?.icon}
                            <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(p.price)}</span>
                          </div>
                        </>
                      }
                      headers={[
                        <AddButton key="add_product" onClick={() => setProductFormVisible(true)}>
                          <span>+ Add product</span>
                        </AddButton>,
                        <AddButton key="add_line" onClick={() => {
                          setLineFormVisible(true)
                          setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0)
                        }}>
                          <span>+ Add line item</span>
                        </AddButton>
                      ]}
                    />
                  </LayoutFade>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    {
                      lineFormVisible &&
                      <div
                        ref={lineFormRef}
                        style={{
                          backgroundColor: 'white',
                          border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                          boxShadow: '0 0 2px lightgray',
                          borderRadius: '6px',
                          padding: 14
                        }}>
                        <div style={{ fontSize: 16, display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                          <span>Add line item</span>
                          <IconButton style={{ padding: 3 }} onClick={() => setLineFormVisible(false)}>
                            <Close style={{ width: '14px' }} />
                          </IconButton>
                        </div>
                        <div style={{ padding: 5, display: 'grid', gridTemplateColumns: '1fr 75px 75px 75px', rowGap: 3, columnGap: 8 }}>
                          <span style={{ opacity: '50%' }}>Name</span>
                          <span style={{ opacity: '50%' }}>Price</span>
                          <span style={{ opacity: '50%' }}>Currency</span>
                          <span style={{ opacity: '50%' }}>Quantity</span>
                          <TextInput
                            placeholder="Name"
                            autoComplete="off"
                            value={newLineItem.name}
                            onChange={(e) => updateNewLineItem({ name: (e.target as HTMLInputElement).value })}
                          />
                          <NumberInput
                            placeholder="Price"
                            min="0"
                            autoComplete="off"
                            value={newLineItem.price}
                            fontSize="14"
                            onUserInput={(e) => {
                              updateNewLineItem({ price: e as any })
                            }}
                          />
                          <Dropdown
                            onCloseFn={fn => setLineCurrencyCloseFn(() => fn)}
                            selectedContent={
                              <div>{newLineItem.currency}</div>
                            }
                            dropdownContent={
                              ACCEPTED_PAYMENT_CURRENCIES
                                .filter(c => newLineItem.currency !== c.ticker)
                                .map((c, i) =>
                                  <DropdownItem
                                    key={i}
                                    onClick={(e) => {
                                      updateNewLineItem({ currency: c.ticker })
                                      lineCurrencyCloseFn?.()
                                    }}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 4
                                    }}
                                  >
                                    { c.logo && <img src={c.logo} style={{ width: 15, height: 15 }} /> }
                                    <span>{c.ticker}</span>
                                  </DropdownItem>
                                )
                            }
                          />
                          <NumberInput
                            placeholder="Qty"
                            min="1"
                            fontSize="14"
                            autoComplete="off"
                            value={newLineItem.quantity}
                            onPaste={e => {
                              e.clipboardData.getData('Text').includes('.') &&
                                e.preventDefault()
                            }}
                            onKeyDown={e => {
                              e.key === '.' && e.preventDefault()
                            }}
                            onUserInput={(e) => updateNewLineItem({ quantity: e as any })}
                          />
                          <div style={{
                            gridColumn: '1 / 5',
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            gap: 4,
                            marginTop: 12
                          }}>
                            <HoverText
                              style={{ marginRight: 5 }}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setLineFormVisible(false)
                              }}>
                            Cancel
                            </HoverText>
                            <PrimaryButton
                              style={{
                                padding: '6px 12px',
                                fontSize: 14
                              }}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                onLineItemSubmit(true)
                              }}>
                              Save and add another
                            </PrimaryButton>
                            <PrimaryButton
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                onLineItemSubmit()
                              }}
                              style={{
                                padding: '6px 12px',
                                fontSize: 14
                              }}>
                              Save
                            </PrimaryButton>
                          </div>
                        </div>
                      </div>
                    }
                    {
                      props.invoice.products.map(p => {
                        return {
                          ...p.product,
                          quantity: p.quantity
                        }
                      }).map((item, i) => {
                        return <InvoiceItem
                          key={i}
                          index={i}
                          item={item}
                          visible={!lineFormVisible}
                          onFormChange={props.onFormChange}
                          onRemoveItem={onRemoveItem}
                          onUserInput={(input) => {
                            const newProducts = props.invoice.products
                            newProducts[i].quantity = input as any
                            props.onFormChange?.({ products: newProducts })
                          }}
                          products={props.invoice.products}
                        />
                      })
                    }
                    {
                      props.invoice.lineItems.map((l, i) => {
                        return {
                          ...l,
                          invoiceId: '',
                          id: `line-${i}`,
                          currency: l.currency ?? 'USD'
                        }
                      }).map((item, i) => {
                        return <InvoiceItem
                          key={item.id}
                          index={i}
                          item={item}
                          visible={!lineFormVisible}
                          onFormChange={props.onFormChange}
                          onRemoveItem={onRemoveItem}
                          onUserInput={(input) => {
                            const newLineItems = props.invoice.lineItems
                            newLineItems[i].quantity = input as any
                            props.onFormChange?.({ lineItems: newLineItems })
                          }}
                          products={props.invoice.products}
                        />
                      })
                    }
                  </div>
                </div>
              </>
            }
            {
              props.formStep === FormStep.CONFIRM &&
              <ReviewGrid>
                {/* Amount */}
                {
                  props.invoice.overdueAt &&
                  <div>
                    <ReviewSubheader>Due date</ReviewSubheader>
                    <div style={{ fontSize: 14 }}>{props.invoice.overdueAt.toLocaleDateString()}</div>
                  </div>
                }
                {/* Email to send to */}
                <div>
                  <ReviewSubheader>Customer</ReviewSubheader>
                  <div style={{ fontSize: 14 }}>{props.invoice.customers[0].name ?? 'Unnamed Customer'} ({props.invoice.customers[0].email})</div>
                </div>
                {/* Memo */}
                {
                  props.invoice.memo &&
                  <div>
                    <ReviewSubheader>Memo</ReviewSubheader>
                    <div style={{ fontSize: 14 }}>{props.invoice.memo}</div>
                  </div>
                }
                {/* Accepted tokens */}
                <div>
                  <ReviewSubheader>Accepted { props.invoice.gateway.managed ? 'currencies' : 'tokens'}</ReviewSubheader>
                  <div style={{ display: 'grid', gap: 8, fontSize: 14 }}>
                    {
                      props.invoice.gateway.managed?.methods
                        .map((m, i) => <PaymentMethodDisplay key={i} method={m} />)
                    }
                    {
                      props.invoice.gateway.selfCustodial?.tokens
                        .map(t => getToken(t.chainId, t.tokenAddress))
                        .filter((value, index, self) => {
                          return self.map(t => t.ticker).indexOf(value.ticker) === index
                        })
                        .map((token, index) =>
                          <TokenDisplay key={index} token={token} />
                        )
                    }
                  </div>
                </div>
              </ReviewGrid>
            }
          </InvoiceFormInner>
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              background: 'linear-gradient(to top, white 75%, transparent)',
              padding: '20px'
            }}>
            <LayoutFade visible={
              !lineFormVisible &&
              (
                props.invoice.gateway.selfCustodial?.tokens.length !== 0 ||
                props.invoice.gateway.managed?.methods.length !== 0
              ) &&
              (props.invoice.lineItems.length !== 0 || props.invoice.products.length !== 0) &&
              props.invoice.customers.length !== 0
            }>
              <PrimaryButton
                isLoading={props.isSubmitting}
                style={{
                  width: '100%'
                }}>
                {
                  props.formStep === FormStep.INVOICE && 'Next'
                }
                {
                  props.formStep === FormStep.CONFIRM &&
                  <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <span>Send invoice ({formatCurrency(lineItemTotal() + productTotal(), currency())})</span>
                    <Send style={{ transform: 'rotate(315deg)', height: 'auto', width: 18 }} stroke="white" />
                  </div>
                }
              </PrimaryButton>
            </LayoutFade>
          </div>
        </InvoiceFormEl>
      </div>
      <Modal
        visible={productFormVisible}
        onClose={() => setProductFormVisible(false)}
        title='Create product'>
        <div style={{ overflow: 'scroll' }}>
          <CreateProduct modal disableRecurring onCreateProduct={onCreateProduct} />
        </div>
      </Modal>
      <Modal
        visible={customerFormVisible}
        onClose={() => setCustomerFormVisible(false)}
        title='Create customer'>
        <div style={{ padding: '0px 30px 30px 30px', overflow: 'scroll' }}>
          <CustomerForm onCreateCustomer={onCreateCustomer} />
        </div>
      </Modal>
    </>
  )
})

export default InvoiceForm
