import React, { ReactElement, useState, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Container } from '../../components/Animations'
import { IconLinkButton, PrimaryButton, SecondaryButton } from '../../components/Button'
import Upload from '../../icons/Upload'
import { RADOM_COLORS } from '../../util/Constants'
import Papa from 'papaparse'
import { useNavigate } from 'react-router-dom'
import Dots from '../../icons/Dots'
import { useTestMode, formatCurrency, errorToast, successToast } from '../../util/Util'
import Table from '../../components/Table'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import { v4 as uuidv4 } from 'uuid'
import PaymentMethodDisplay from '../../components/PaymentMethodDisplay'
import Pagination from '../../components/Pagination'
import EditPayoutModal from './EditPayoutModal'
import Radom from '../../state/Radom'
import { InputLabel, OptionalLabel, TextInputWithLabel } from '../../components/Input'
import Checkbox from '../../components/Checkbox'
import Treasury from '../../state/Treasury'
import { getMethod, ManagedPaymentMethod } from '../../util/Managed'
import styled from 'styled-components'
import User from '../../state/User'
import Download from '../../icons/Download'
import NavbarController from '../../state/NavbarController'
import Close from '../../icons/Close'
import { ManagedPaymentMethodSelector } from '../../components/ManagedMethodMultiselector'
import NumberInput from '../../components/NumberInput'
import { Country } from 'country-state-city'
import SearchMultiselect from '../../components/SearchMultiselect'
import DateSelector from '../../components/DateSelector'
import Spinner from '../../components/Spinner'
interface EUR_Account {
  iban: string
  bic: string
}

interface USD_Account {
  account_number: string
  routing_number: string
  bank_name: string
}

enum CurrencyType {
  EUR = 'EUR',
  USD = 'USD'
}
enum AccountType {
  Individual = 'Individual',
  Business = 'Business'
}

interface PayoutEntry {
  id: string
  network: string
  token?: string
  amount?: string
  payoutAddress?: string
  name?: string
  dateOfBirth?: string
  residenceCountry?: string
  email?: string
  payout_currency?: CurrencyType
  payment_rail?: string
  accountType?: AccountType
  address?: string
  eur_account?: EUR_Account
  usd_account?: USD_Account
  companyNumber?: string
}

enum payType {
  Crypto,
  Fiat
}

interface TreasuryBalance {
  method: ManagedPaymentMethod
  network: string
  balance: string
}

const InputPresetList = styled.div`
  display: grid;
  gap: 12px;
  align-items: center;
`

const InputPreset = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  width: fit-content;
  transition: 0.2s ease all;

  &:hover {
    color: ${RADOM_COLORS.BLACK};
  }

  &:active {
    opacity: 0.5;
  }
`

const CreateMassPayout = observer((): ReactElement => {
  const navigate = useNavigate()
  const [payoutEntries, setPayoutEntries] = useState<PayoutEntry[]>([])
  const [displayPayoutEntries, setDisplayPayoutEntries] = useState<PayoutEntry[]>([])
  const [selectPayType, setSelectPayType] = useState<payType>(payType.Crypto)
  const [file, setFile] = useState<File>()
  const [isLoading, setIsLoading] = useState(false)
  const [curPage, setCurPage] = useState(0)
  const [numPages, setNumPages] = useState(0)
  const CloseFns = {}
  const pageSize = 50
  const [editPayout, setEditPayout] = useState<PayoutEntry | null>(null)
  const [testMode] = useTestMode()
  const [treasuryBalances, setTreasuryBalances] = useState<TreasuryBalance>()
  const [closeModalFn, setCloseModalFn] = useState(() => () => {})
  const [selectedMethod, setSelectedMethod] = useState<ManagedPaymentMethod>()
  const [templatePayoutEntry, setTemplatePayoutEntry] = useState<PayoutEntry>({
    id: uuidv4(),
    network: '',
    amount: '',
    payoutAddress: '',
    name: '',
    dateOfBirth: '',
    residenceCountry: '',
    email: '',
    payout_currency: undefined,
    payment_rail: '',
    accountType: AccountType.Individual,
    address: '',
    eur_account: undefined,
    usd_account: undefined,
    companyNumber: ''
  })
  const [showAddPanel, setShowAddPanel] = useState(false)
  const isButtonDisabled = !(
    templatePayoutEntry.amount &&
    templatePayoutEntry.payoutAddress &&
    templatePayoutEntry.name &&
    templatePayoutEntry.email
  )

  useEffect(() => {
    NavbarController.setFullscreen(true)
    return () => NavbarController.setFullscreen(false)
  }, [])

  const createPayout = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const payout = await Radom.createPayout({
        payouts: payoutEntries.map(p => ({
          crypto: {
            ...p
          }
        }))
      })
      console.log('Payout created:', payout)
      navigate('/payouts')
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleEditPayout = (payout: PayoutEntry): void => {
    setEditPayout(payout)
  }

  const countries = useMemo(() => Country
    .getAllCountries()
    .map((country) => ({
      key: country.isoCode,
      value: country.name
    })),
  [])

  const deletePayout = (payout: PayoutEntry): void => {
    setPayoutEntries((prev) => {
      const newPayoutEntries = prev.filter((p) => p.id !== payout.id)
      const newNumPages = Math.ceil(newPayoutEntries.length / pageSize)

      setNumPages(newNumPages)

      let newCurPage = curPage
      if (newCurPage >= newNumPages) {
        newCurPage = Math.max(newNumPages - 1, 0)
      }

      setCurPage(newCurPage)
      setDisplayPayoutEntries(newPayoutEntries.slice(newCurPage * pageSize, (newCurPage + 1) * pageSize))
      return newPayoutEntries
    })

    successToast('Payout deleted successfully')
  }
  const handleSavePayout = (updatedPayout: PayoutEntry): void => {
    console.log('Updated payout:', updatedPayout)
    setPayoutEntries((prev) =>
      prev.map((p) => (p.id === updatedPayout.id ? updatedPayout : p))
    )
    setDisplayPayoutEntries((prev) =>
      prev.map((p) => (p.id === updatedPayout.id ? updatedPayout : p))
    )

    successToast('Payout updated successfully')
    setEditPayout(null)
  }

  const pareseCSV = (file: File): void => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const newPayouts = [] as any[]
        const row = results.data[0]
        if (!row.network && !row.payout_currency) return
        const allowNetwork = ['Ethereum', 'SepoliaTestnet', 'Tron']
        if (!allowNetwork.includes(row.network)) {
          errorToast('Only Ethereum and Tron are currently supported for payouts')
          return
        }

        if (row.network && selectPayType === payType.Fiat) {
          errorToast('Please upload the correct CSV file')
          return
        }
        if (row.payout_currency && selectPayType === payType.Crypto) {
          errorToast('Please upload the correct CSV file')
          return
        }
        results.data.forEach((row) => {
          for (let i = 0; i < 1; i++) {
            if (!row.network && !row.payout_currency) continue
            let dob = row['date_of_birth (INDIVIDUAL ONLY)']
            try {
              new Date(dob).toISOString()
            } catch (e) {
              dob = ''
            }
            console.log('Date of birth:', dob)
            const t: PayoutEntry = {
              id: uuidv4(),
              accountType: row.account_type,
              network: row.network,
              token: row.token_address.length > 0 ? row.token_address : undefined,
              amount: row.amount,
              payoutAddress: row.payout_address,
              email: row.email,
              name: row.beneficiary_name,
              dateOfBirth: dob,
              companyNumber: row['company_number (BUSINESS ONLY)'],
              residenceCountry: row.country_of_beneficiary,
              address: row.beneficiary_address
            }
            if (selectPayType === payType.Fiat) {
              t.payment_rail = row.payment_rail
              if (row.payout_currency === 'EUR') {
                t.payout_currency = CurrencyType.EUR
                t.eur_account = {
                  iban: row['iban (EURO ONLY)'],
                  bic: row['bic (EURO ONLY)']
                }
              } else {
                t.payout_currency = CurrencyType.USD
                t.usd_account = {
                  account_number: row['account_number (USD) ONLY'],
                  routing_number: row['routing_number (USD ONLY)'],
                  bank_name: row['bank_name (USD ONLY)']
                }
              }
            }
            newPayouts.push(t)
          }
        })
        setNumPages(Math.ceil(newPayouts.length / pageSize))
        setPayoutEntries(newPayouts)
        setDisplayPayoutEntries(newPayouts.slice(0, pageSize))
      },
      error: (error) => {
        console.error('Error parsing CSV:', error)
        errorToast('Error parsing CSV')
      }
    })
  }

  const handleFileUpload = (event): any => {
    setIsLoading(true)
    const file = event.target.files[0]
    setFile(file)
    if (!file) return
    successToast('CSV file uploaded successfully')
    setIsLoading(false)
  }

  useEffect(() => {
    Treasury.loadBalances()
    Treasury.loadPriceQuotes()
  }, [])

  return <Container style={{
    padding: 0,
    flexDirection: 'column',
    overflow: 'visible'
  }}>
    {editPayout && (
      <EditPayoutModal
        payout={editPayout}
        onClose={() => setEditPayout(null)}
        onSave={handleSavePayout}
        visible={editPayout !== null}
      />
    )}

    <div style={{
      position: 'sticky',
      top: 0,
      zIndex: 1
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`,
        padding: '15px 20px',
        background: 'white'
      }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <IconLinkButton to='/payouts' type="button" style={{
            padding: 5,
            cursor: isLoading ? 'not-allowed' : 'pointer'
          }} onClick={(e) => isLoading && e.preventDefault()}>
            <Close style={{ width: 15, pointerEvents: 'none' }} />
          </IconLinkButton>
          <span style={{ fontSize: 18 }}>Create payout</span>
        </div>
        {
          payoutEntries.length === 0 &&
          <PrimaryButton style={{ gap: 8 }} onClick={() => {
            window.open('https://s3.us-east-1.amazonaws.com/test.radom.network/Radom+-+Mass+Crypto+Payouts+Template.csv', '_blank')
          }}>
            <span>Download template</span>
            <Download />
          </PrimaryButton>
        }
        {
          payoutEntries.length > 0 &&
          <div style={{
            display: 'flex',
            flexDirection: 'row'
          }}>
            <div
              style={{
                backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
                width: 51,
                height: 51,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 50,
                marginRight: 10,
                fontSize: 20,
                cursor: isLoading ? 'not-allowed' : 'pointer',
                pointerEvents: isLoading ? 'none' : 'auto'
              }}
              onClick={() => setShowAddPanel(true)}
            >
              +
            </div>
            <div style={{
              display: 'flex',
              gap: 30,
              background: RADOM_COLORS.GRAY_LIGHTEST,
              borderRadius: 25,
              padding: 5,
              paddingLeft: 30
            }}>
              <div style={{
                alignItems: 'center',
                fontSize: 14,
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 20
              }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: 10, textTransform: 'uppercase', opacity: 0.5 }}>Payouts</span>
                  <span>{payoutEntries.length}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: 10, textTransform: 'uppercase', opacity: 0.5 }}>Total</span>
                  <span>{
                    formatCurrency(
                      payoutEntries.reduce((total, b) => {
                        const method = getMethod(b.network, b.token)
                        const conversion = Treasury.priceQuotes
                          .find(p => p.from.toLowerCase() === method.ticker.toLowerCase())

                        return total + b.amount * conversion?.value
                      }, 0)
                      , User.organization?.defaultCurrency ?? 'USD')
                  }</span>
                </div>
              </div>

              <PrimaryButton style={{ gap: 8 }} onClick={createPayout} isLoading={isLoading}>
                <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
                <span>Create payout</span>
              </PrimaryButton>
            </div>
          </div>
        }

      </div>
    </div>
    {showAddPanel && (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(7, 7, 40, 0.75)', // Semi-transparent dark overlay
          zIndex: 99 // Behind the side panel
        }}
        onClick={() => setShowAddPanel(false)} // Clicking outside closes the panel
      />
    )}

    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: 'auto',
        height: '100%',
        background: 'white',
        fontSize: 14,
        boxSizing: 'border-box',
        boxShadow: '2px 0 5px rgba(0,0,0,0.3)',
        transform: showAddPanel ? 'translateX(0)' : 'translateX(-150%)',
        transition: 'transform 0.3s ease',
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        overflow: 'hidden'
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'auto'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          top: 0,
          width: '100%',
          position: 'absolute',
          alignItems: 'center',
          padding: '10px 30px',
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(255, 255, 255, 0.85)',
          borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`,
          zIndex: 10,
          columnGap: 10
        }}>
          <Close style={{ width: 15, cursor: 'pointer' }} onClick={() => { setShowAddPanel(false) }}/>
          <h1 style={{ fontWeight: 400, fontSize: 18 }}>Add a new payout</h1>
        </div>
        <div
          style={{
            fontSize: 14,
            width: 450,
            height: '100%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: 40,
            paddingTop: 90,
            gap: 15,
            overflowY: 'auto'
          }}
        >
          <ManagedPaymentMethodSelector
            hideDisabled
            disabled={isLoading}
            placeholderText='Select asset'
            selectedMethods={selectedMethod ? [selectedMethod] : []}
            setSelectedMethods={(methods) => setSelectedMethod(methods[methods.length - 1])}
            allowedNetwork={payoutEntries.length > 0 ? payoutEntries[0].network : undefined}
            testnetOnly={testMode}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <InputLabel>Payout amount</InputLabel>
            <NumberInput
              disabled={isLoading}
              style={{ minWidth: 100 }}
              value={templatePayoutEntry.amount}
              onUserInput={(v) => {
                setTemplatePayoutEntry((prev) => ({ ...prev, amount: v }))
              }
              }
            />
          </div>
          <TextInputWithLabel
            required
            label="Payout address"
            placeholder='Enter payout address'
            value={templatePayoutEntry.payoutAddress}
            onChange={(e) => {
              setTemplatePayoutEntry((prev) => ({ ...prev, payoutAddress: (e.target as HTMLInputElement).value }))
            }}
          />

          <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', gap: 10 }}>
            <InputLabel>
              <span>Account owner type</span>
            </InputLabel>
            <Dropdown
              selectedContent={
                templatePayoutEntry.accountType
              }
              onCloseFn={_closeFn => setCloseModalFn(() => _closeFn)}
              dropdownContent={
                [AccountType.Business, AccountType.Individual].map((type) => (
                  <DropdownItem
                    key={type}
                    onClick={() => {
                      setTemplatePayoutEntry((prev) => ({ ...prev, accountType: type }))
                      closeModalFn()
                    }}>
                    {type === AccountType.Business ? 'Business' : 'Individual'}
                  </DropdownItem>
                ))
              }
            />
          </div>
          <TextInputWithLabel
            required
            label="Beneficiary name"
            placeholder='Enter beneficiary name'
            value={templatePayoutEntry.name}
            onChange={(e) => {
              setTemplatePayoutEntry((prev) => ({ ...prev, name: (e.target as HTMLInputElement).value }))
            }}
          />

          <TextInputWithLabel
            required
            label = "Beneficiary email"
            placeholder='Enter beneficiary email'
            value={templatePayoutEntry.email}
            onChange={(e) => {
              setTemplatePayoutEntry((prev) => ({ ...prev, email: (e.target as HTMLInputElement).value }))
            }}
          />
          {
            templatePayoutEntry.accountType === AccountType.Individual &&
          <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', gap: 5 }}>
              <InputLabel>
                <span>Date of birth</span>
              </InputLabel>
              <OptionalLabel>Optional</OptionalLabel>
            </div>

            <DateSelector
              value={templatePayoutEntry.dateOfBirth ? new Date(templatePayoutEntry.dateOfBirth) : undefined}
              onChange={(date) => {
                setTemplatePayoutEntry((prev) => ({ ...prev, dateOfBirth: date?.toLocaleDateString() || '' }))
              }}
            />
          </div>
          }

          {
            templatePayoutEntry.accountType === AccountType.Business &&
          <TextInputWithLabel
            required
            label="Company number"
            value={templatePayoutEntry.companyNumber}
            onChange={(e) => {
              setTemplatePayoutEntry((prev) => ({ ...prev, companyNumber: (e.target as HTMLInputElement).value }))
            }}
          />
          }
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', gap: 5 }}>
              <InputLabel>
                <span>Residence</span>
              </InputLabel>
              <OptionalLabel>Optional</OptionalLabel>
            </div>

            <SearchMultiselect
              isSingleSelect
              placeholder='Select residence'
              dropdownPlaceholder="No countries found"
              itemArray={countries}
              selectedItems={
                templatePayoutEntry.residenceCountry
                  ? [{ key: templatePayoutEntry.residenceCountry, value: templatePayoutEntry.residenceCountry }]
                  : []}
              setSelectedItems={(residence) => {
                setTemplatePayoutEntry((prev) => ({ ...prev, residenceCountry: residence[0].value }))
              }}
              keyExtractor={(item) => item.value}
              labelExtractor={(item) => item.value}
            />
          </div>

          <TextInputWithLabel
            label="Address"
            placeholder='Enter beneficiary address'
            value={templatePayoutEntry.address}
            onChange={(e) => {
              setTemplatePayoutEntry((prev) => ({ ...prev, address: (e.target as HTMLInputElement).value }))
            }}
          />

        </div>
        <div style={{
          position: 'sticky',
          bottom: 0,
          padding: '20px 40px',
          background: 'linear-gradient(to top, white, white, transparent)',
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          height: 'auto'
        }}>
          <PrimaryButton
            style={{
              width: '100%'
            }}
            disabled={isButtonDisabled}
            onClick={() => {
              if (!templatePayoutEntry.amount ||
              !templatePayoutEntry.payoutAddress ||
                !templatePayoutEntry.name ||
                !templatePayoutEntry.email) {
                errorToast('Please fill in all fields')
                return
              }
              templatePayoutEntry.network = selectedMethod?.hostChain?.name ?? selectedMethod?.name ?? ''
              templatePayoutEntry.token = selectedMethod?.hostChain?.tokenAddress
              setPayoutEntries((prev) => {
                const newPayoutEntries = [...prev, templatePayoutEntry]
                const newNumPages = Math.ceil(newPayoutEntries.length / pageSize)

                setNumPages(newNumPages)

                let newCurPage = curPage
                if (newCurPage >= newNumPages) {
                  newCurPage = Math.max(newNumPages - 1, 0)
                }

                setCurPage(newCurPage)
                setDisplayPayoutEntries(newPayoutEntries.slice(newCurPage * pageSize, (newCurPage + 1) * pageSize))
                setTemplatePayoutEntry({
                  id: uuidv4(),
                  network: '',
                  amount: '',
                  payoutAddress: '',
                  name: '',
                  dateOfBirth: '',
                  residenceCountry: '',
                  email: '',
                  payout_currency: undefined,
                  payment_rail: '',
                  accountType: AccountType.Individual,
                  address: '',
                  eur_account: undefined,
                  usd_account: undefined,
                  companyNumber: ''
                })
                return newPayoutEntries
              })
              setShowAddPanel(false)
              successToast('Payout added successfully')
            }
            }
          >
          Add payout
          </PrimaryButton>
        </div>
      </div>

    </div>

    <div style={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 20,
      margin: 30
    }}
    >
      {
        payoutEntries.length === 0 &&
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
          padding: '80px 20px',
          borderRadius: 15
        }}>
          <div style={{
            width: '100%',
            maxWidth: 500
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>

              {false && <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Select funding from</InputLabel>
                <Dropdown
                  key={treasuryBalances?.network + treasuryBalances?.network}
                  dropdownContent={
                    <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                      {
                        Treasury.managedBalances
                          .filter(b => Number(b.balance) > 0)
                          .filter(b => {
                            const m = getMethod(b.network, b.token)?.isTestnet
                            return testMode ? m : !m
                          })
                          .map(b => {
                            const method = getMethod(b.network, b.token)
                            const conversion = Treasury.priceQuotes
                              .find(p => p.from.toLowerCase() === method.ticker.toLowerCase())
                            return [
                              <DropdownItem key="token" style={{ display: 'flex', fontSize: 14, gap: 8 }}
                                onClick={() => {
                                  setTreasuryBalances({
                                    method,
                                    network: b.network,
                                    balance: b.balance
                                  })
                                }
                                }
                              >
                                <PaymentMethodDisplay
                                  method={{ network: b.network, token: b.token }}
                                  excludeName size={18}
                                />
                                <div key="value" style={{ display: 'flex', gap: 3 }}>
                                  <div>
                                    {
                                      new Intl.NumberFormat('en-US', {
                                        maximumSignificantDigits: 18
                                      }).format(b.balance as any)
                                    }
                                  </div>
                                  <div>
                                    {method.ticker}
                                    {
                                      conversion
                                        ? <span style={{ opacity: 0.5 }}>
                              &nbsp;≈ {formatCurrency(Number(b.balance) * (conversion.value), User.organization?.defaultCurrency ?? 'USD')}
                                        </span>
                                        : <></>
                                    }
                                  </div>
                                </div>
                              </DropdownItem>
                            ]
                          })
                      }
                    </div>
                  }
                  selectedContent={
                    treasuryBalances
                      ? (
                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                          <PaymentMethodDisplay method={{
                            network: treasuryBalances.network,
                            token: treasuryBalances.method.hostChain?.tokenAddress
                          }} excludeName size={18} />
                          <div style={{ fontSize: 14 }}>
                            {formatCurrency(treasuryBalances.balance, treasuryBalances.method.ticker)}
                          </div>
                        </div>
                      )
                      : (
                        <div
                          style={{
                            fontSize: 14,
                            alignItems: 'center',
                            display: 'flex',
                            gap: 10
                          }}>
                    Select funding
                        </div>
                      )
                  }
                />

              </div>
              }

              {/** TODO: Feature email notification */}

              {false &&
              <>
                <InputLabel style={{ opacity: 1, gap: 2 }}>
                  <span style={{ opacity: 0.5 }}>Email receipt</span>
                </InputLabel>
                <InputPresetList>
                  <InputPreset>
                    <Checkbox
                      size={16}
                      checked={false} />
                    <span>Send customer an email receipt</span>
                  </InputPreset>
                </InputPresetList>
              </>
              }

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>What currencies are you paying out in?</InputLabel>
                <div style={{ fontSize: 14, display: 'flex', gap: 20 }}>
                  <SecondaryButton
                    disabled={isLoading}
                    onClick={() => setSelectPayType(payType.Crypto)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 10,
                      border: 0,
                      padding: 5,
                      color: selectPayType === payType.Crypto ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
                    }}
                    type='button'>
                    <Checkbox isRadio checked={selectPayType === payType.Crypto} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <span>Crypto</span>
                      <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                              Transfer to a crypto address
                      </span>
                    </div>
                  </SecondaryButton>

                  <SecondaryButton
                    disabled={true}
                    onClick={() => setSelectPayType(payType.Fiat)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 10,
                      border: 0,
                      padding: 5,
                      color: selectPayType === payType.Fiat ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
                    }}
                    type='button'>
                    <Checkbox isRadio checked={selectPayType === payType.Fiat} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <span>Fiat</span>
                      <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                              Offramp to a bank account
                      </span>
                    </div>
                  </SecondaryButton>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Upload CSV</InputLabel>

                <div style={{
                  border: '1px dashed #ccc',
                  padding: 20,
                  borderRadius: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <SecondaryButton
                    style={{
                      fontSize: 14,
                      width: '100%',
                      height: 100,
                      backgroundColor: 'transparent',
                      border: 'none',
                      color: RADOM_COLORS.GRAY_DARKEST,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 0,
                      gap: 5,
                      position: 'sticky'
                    }}>
                    <label style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 5 }}>
                      {file?.name || 'Import from CSV'}
                      <Upload stroke={RADOM_COLORS.GRAY_DARKEST} style={{ width: 18, height: 18 }} />
                      <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileUpload}
                        style={{
                          opacity: 0,
                          cursor: 'pointer',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0
                        }} // Hide the file input
                      />
                    </label>
                  </SecondaryButton>
                </div>
              </div>

              <PrimaryButton style={{ gap: 8, width: '100%', marginTop: 20 }} onClick={() => {
                if (!file) {
                  errorToast('Please upload a CSV file')
                  return
                }
                pareseCSV(file)
              }}>
                <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
                <span>Create payout</span>
              </PrimaryButton>

              <span
                style={{
                  fontSize: 14,
                  textAlign: 'center',
                  cursor: 'pointer',
                  color: RADOM_COLORS.GRAY_DARKER
                }}
                onClick={() => {
                  setShowAddPanel(true)
                }}
              >
                Manually add a payout</span>
            </div>
          </div>
        </div>
      }
      {/* Crpyto Table Display */}
      {
        payoutEntries.length > 0 && payType.Crypto === selectPayType &&
        <>
          <Table
            borderless
            isLoading={isLoading}
            headers={[
              '#',
              'Network',
              'Amount',
              'Payout address',
              'Beneficiary name',
              'Email',
              ...(displayPayoutEntries.some(p => p.address) ? ['Address'] : []),
              ...(displayPayoutEntries.some(p => p.residenceCountry) ? ['Residence'] : []),
              ''
            ].map(h => <span key={h}>{h}</span>)}
            items={displayPayoutEntries.map((p, i) => {
              const method = getMethod(p.network, p.token)
              const conversion = Treasury.priceQuotes.find(
                q => q.from.toLowerCase() === method.ticker.toLowerCase()
              )

              const row = [
                <span key={i}>{curPage * pageSize + i + 1}</span>,
                <PaymentMethodDisplay key={p.id} method={{ network: p.network, token: p.token }} />,
                <div key={p.id} style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <div>{p.amount} {method.ticker}</div>
                  <div style={{ color: RADOM_COLORS.GRAY4 }}>
                    ≈ {conversion ? formatCurrency(Number(p.amount) * conversion.value, User.organization?.defaultCurrency ?? 'USD') : ''}
                  </div>
                </div>,
                <div key={p.id}>{p.payoutAddress}</div>,
                <div key={p.id} style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  {p.accountType === AccountType.Individual && (
                    <>
                      <div>{p.name}</div>
                      <div style={{ color: RADOM_COLORS.GRAY4 }}>{p.dateOfBirth}</div>
                    </>
                  )}
                  {p.accountType === AccountType.Business && (
                    <>
                      <div>{p.name}</div>
                      <div style={{ color: RADOM_COLORS.GRAY4 }}>{p.companyNumber}</div>
                    </>
                  )}
                </div>,
                <div key={p.id}>{p.email}</div>
              ]

              if (displayPayoutEntries.some(p => p.address)) {
                row.push(<div key={p.id}>{p.address || <span>&nbsp;</span>}</div>)
              }

              if (displayPayoutEntries.some(p => p.residenceCountry)) {
                row.push(<div key={p.id}>{p.residenceCountry || <span>&nbsp;</span>}</div>)
              }

              row.push(
                <div key={p.id} style={{ display: 'flex' }}>
                  <Dropdown
                    noChevron
                    disabled={isLoading}
                    selectedContent={<Dots width={15} />}
                    key={p.id}
                    onCloseFn={f => {
                      CloseFns[p.id] = f
                    }}
                    dropdownContent={
                      <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                        <DropdownItem onClick={() => handleEditPayout(p)}>Edit Payout</DropdownItem>
                        <DropdownItem onClick={() => deletePayout(p)}>Delete</DropdownItem>
                      </div>
                    }
                  />
                </div>
              )

              return row
            })}
          />
          <Pagination
            curPage={curPage}
            numPages={numPages}
            onPageClick={(page) => {
              setCurPage(page)
              setDisplayPayoutEntries(payoutEntries.slice(page * pageSize, (page + 1) * pageSize))
            }}
          />
        </>
      }
      {/* Fiat Table Display */}
      {
        payoutEntries.length > 0 && payType.Fiat === selectPayType &&
        <>
          <Table
            borderless
            isLoading={isLoading}
            headers={['Currency', 'Amount', 'Account type', 'Bank Detail', 'Beneficiary Name', 'Address', 'Residence', 'Email', ''].map(h => <span key={h}>{h}</span>)}
            items={
              displayPayoutEntries.map(p => {
                return [
                  <div key={p.id}>
                    {
                      p.payout_currency === CurrencyType.EUR
                        ? '€ EUR'
                        : '$ USD'
                    }
                    <span style={{ color: RADOM_COLORS.GRAY4 }}> {p.payment_rail}</span>
                  </div>,
                  <div key={p.id}>{p.amount}</div>,
                  <div key={p.id}>{p.accountType}</div>,
                  <div key={p.id} style={{ display: 'flex', flexDirection: 'column', gap: 3, fontSize: 12 }}>
                    {
                      p.eur_account &&
                      <>
                        <div><div style={{ color: RADOM_COLORS.GRAY4 }} >IBAN</div> {p.eur_account.iban}</div>
                        <div><div style={{ color: RADOM_COLORS.GRAY4 }} >BIC</div> {p.eur_account.bic}</div>
                      </>
                    }
                    {
                      p.usd_account &&
                      <>
                        <div><div style={{ color: RADOM_COLORS.GRAY4 }} >
                          Account Number</div> {p.usd_account.account_number}
                        </div>
                        <div><div style={{ color: RADOM_COLORS.GRAY4 }} >
                          Routing Number</div> {p.usd_account.routing_number}
                        </div>
                        <div><div style={{ color: RADOM_COLORS.GRAY4 }} >Bank Name</div> {p.usd_account.bank_name}</div>
                      </>
                    }
                  </div>,
                  <div key={p.id} style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    {
                      p.accountType === AccountType.Individual &&
                                    <>
                                      <div>{p.name}</div>
                                      <div style={{ color: RADOM_COLORS.GRAY4 }}>{p.dateOfBirth}</div>
                                    </>
                    }
                    {
                      p.accountType === AccountType.Business && p.accountType &&
                                      <>
                                        <div>{p.name}</div>
                                        <div style={{ color: RADOM_COLORS.GRAY4 }}>{p.companyNumber}</div>
                                      </>
                    }
                  </div>,
                  <div key={p.id}>{p.address}</div>,
                  <div key={p.id}>{p.residenceCountry}</div>,
                  <div key={p.id}>{p.email}</div>,
                  <div key={p.id} style={{ display: 'flex' }}>
                    <Dropdown
                      noChevron
                      disabled={isLoading}
                      selectedContent={<Dots width={15} />}
                      key={p.id}
                      onCloseFn={
                        f => {
                          CloseFns[p.id] = f
                        }
                      }
                      dropdownContent={
                        <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                          <DropdownItem onClick={() => handleEditPayout(p)}>Edit Payout</DropdownItem>
                          <DropdownItem onClick={() => deletePayout(p)}>Delete</DropdownItem>
                        </div>
                      }
                    />
                  </div>

                ]
              })
            }
          />
          <Pagination curPage={curPage} numPages={numPages} onPageClick={(page) => {
            setCurPage(page)
            setDisplayPayoutEntries(payoutEntries.slice(page * pageSize, (page + 1) * pageSize))
          }} />
        </>
      }
    </div>
  </Container>
})

export default CreateMassPayout
